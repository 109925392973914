import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const EMERGENCY_ROOM_EXCHANGE_CARD_FORM113_ROUTE: RouteRecordRaw = {
  name: 'EmergencyRoomExchangeCardForm113',
  path: ROUTE_PATH.emergencyRoom.emergencyRoomForm113.rawPath,
  component: () => import('./ExchangeCardForm113/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form113.ExchangeCardForm113',
  },
};

export const MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE: RouteRecordRaw = {
  name: 'MedicalCaseExchangeCardForm113',
  path: ROUTE_PATH.appointments.medicalCaseForm113.rawPath,
  component: () => import('./ExchangeCardForm113/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form113.ExchangeCardForm113',
  },
};

export const MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE: RouteRecordRaw = {
  name: 'MedicalCaseExchangeCardForm113Edit',
  path: ROUTE_PATH.appointments.medicalCaseForm113Edit.rawPath,
  component: () => import('./ExchangeCardForm113Edit/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form113.ExchangeCardForm113',
  },
};

export const UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_ROUTE: RouteRecordRaw = {
  name: 'UnscheduledMedicalCaseExchangeCardForm113',
  path: ROUTE_PATH.appointments.unscheduledMedicalCaseForm113.rawPath,
  component: () => import('./ExchangeCardForm113/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form113.ExchangeCardForm113',
  },
};

export const UNSCHEDULED_MEDICAL_CASE_EXCHANGE_CARD_FORM113_EDIT_ROUTE: RouteRecordRaw = {
  name: 'UnscheduledMedicalCaseExchangeCardForm113Edit',
  path: ROUTE_PATH.appointments.unscheduledMedicalCaseForm113Edit.rawPath,
  component: () => import('./ExchangeCardForm113Edit/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Form113.ExchangeCardForm113',
  },
};
