<template>
  <div class="patients-search-select">
    <MiSelectSearch
      class="patients-search-select__component"
      label-key="name"
      value-key="id"
      v-bind="$attrs"
      v-model="value"
      v-model:model-object-value="objectValue"
      raw-fetch-result
      not-call-with-empty-query
      :fetch-data="misApi.gen.raw.v2.patients.searchPatients">
      <template #option="{ item }">
        <div class="patients-search-select__option">
          <div class="patients-search-select__option-wrapper">
            <span> {{ joinFullname(item) }}</span>
            <span>{{ convertDateToAppFormat(item.birthdate) }}</span>
          </div>
          <span class="patients-search-select__option-medcard">{{ item.medcard_number }}</span>
        </div>
      </template>
    </MiSelectSearch>
    <slot name="append" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { convertDateToAppFormat } from '~shared/lib';
import { AnyObject } from '~shared/types';
import { MiSelectSearch } from '~shared/ui';
import { misApi } from '~shared/api';
import { joinFullname } from '~shared/lib';

type Props = {
  modelValue: string | string[] | number | number[] | undefined;
  modelObjectValue: AnyObject | AnyObject[] | undefined;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [e: Props['modelValue']];
  'update:modelObjectValue': [e: Props['modelObjectValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const objectValue = computed({
  get() {
    return props.modelObjectValue;
  },
  set(val) {
    // для проверки ts
    if (val && 'name' in val && 'patronymic' in val && 'surname' in val) {
      emit('update:modelObjectValue', {
        ...val,
        name: joinFullname(val),
      });
    } else {
      emit('update:modelObjectValue', val);
    }
  },
});
</script>

<style lang="scss" src="./index.scss" />
