<template>
  <div class="patients-search-select-data-block">
    <PatientsSearchSelect
      :model-value="patient?.id"
      v-model:model-object-value="patient"
      @update:model-value="$emit('update:modelValue', $event)"
      :placeholder="$t('Patients.SelectPatient')"
      clearable>
      <template #append>
        <ScanPatientBracelet only-icon @successful-scan="handleScanPatientBracelet" />
      </template>
    </PatientsSearchSelect>

    <div class="patients-search-select-data-block__content">
      <ElFormItem :label="$t('User.FullName')">
        {{ patient?.name || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Birthdate')">
        {{ convertDateToAppFormat(patient?.birthdate) || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('Contingent.Medcard')">
        {{ patient?.medcard_number || patient?.number_med_card || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Phone')">
        {{ patient?.phone || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Gender')">
        {{ patient?.id && patient?.gender ? $t(`User.Genders.${patient?.gender}`) : '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.PlaceResidence')">
        {{ patient?.address || patient?.place_residence || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('Contingent.PriceListCategory')">
        {{ patient?.price_list?.title || '-' }}
      </ElFormItem>

      <slot name="content-append"></slot>
    </div>
  </div>
</template>

<script>
import { ElFormItem } from 'element-plus';

// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
import { Appointment } from '../models/Appointment.model';
// eslint-disable-next-line import/no-internal-modules
import { Patient } from '../models/Patient.model';

import { PatientsSearchSelect } from '~features/PatientsSearchSelect';
import { ScanPatientBracelet } from '~features/ScanPatientBracelet';
import { convertDateToAppFormat } from '~shared/lib';

export default {
  name: 'PatientsSearchSelectDataBlock',
  components: { ScanPatientBracelet, PatientsSearchSelect, ElFormItem },
  emits: ['update:modelValue', 'select', 'reference:update', 'createPatient'],
  props: {
    modelValue: Number,
    defaultItem: [Patient, Object],
    required: Boolean,
    disabled: Boolean,
    searchQuery: Object,
    hideSelect: Boolean,
    showCreateOption: Boolean,
    appointment: {
      type: [Appointment, Object],
    },
  },
  data() {
    return {
      patient: null,

      patientPart: {
        show: false,
        nameOrPhone: null,
        newPatient: null,
      },
    };
  },
  computed: {
    query() {
      return {
        query_field: ['name', 'phone'],
        ...(this.searchQuery ?? {}),
      };
    },
  },

  watch: {
    defaultItem: {
      handler(value) {
        this.patient = value ?? new Patient();
      },
      immediate: true,
    },
    modelValue(val) {
      if (!val) {
        this.patient = null;
      }
    },
  },

  methods: {
    convertDateToAppFormat,
    selectHandler(data) {
      this.patient = data;
      this.$emit('select', data);
    },

    handleScanPatientBracelet(fullPatient) {
      this.patient = fullPatient;
      this.$emit('select', fullPatient);
    },

    insertPatient(action) {
      this.$emit('update:modelValue', action.data.patient.id);
      this.patientPart.show = false;
      this.selectHandler(action.data.patient);
    },

    focus() {
      setTimeout(() => this.$refs.select.focus());
    },
    blur() {
      setTimeout(() => this.$refs.select.blur());
    },

    createHandler() {
      this.$emit('createPatient');
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
