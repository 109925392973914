import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ANALYTIC_BY_SICKNESS_ROUTE = {
  name: 'AnalyticBySickness',
  path: ROUTE_PATH.analyticBySickness,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'doctor'])],
  meta: {
    title: 'Analytics.SicknessRate',
  },
};
