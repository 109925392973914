<template>
  <ElDialog
    width="480px"
    class="product-crud-modal"
    :title="isNewProduct ? $t('Product.AddProduct') : $t('Product.EditProduct')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      class="product-crud-form"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      v-slot="{ meta }"
      @submit="onSubmit">
      <FieldGroupWrapper>
        <FormTextField
          :label="$t('Nomenclature.NameOfProduct')"
          field-name="title"
          required
          maxlength="255" />
        <FormTextField
          :label="$t('Nomenclature.VendorCode')"
          field-name="article"
          required
          maxlength="255" />
        <FormTextField :label="$t('Income.Price')" field-name="price" required mask-name="int-12" />
        <FormTextField
          :label="$t('Nomenclature.Notes')"
          field-name="description"
          type="textarea"
          :rows="4"
          maxlength="255" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <MiButton
          v-if="isNewProduct"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ `+ ${$t('Common.Add')}` }}
        </MiButton>
        <MiButton
          v-if="!isNewProduct"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ $t('Common.Save') }}
        </MiButton>
        <ElPopconfirm
          v-if="!isNewProduct"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="onDeleteButtonClick">
          <template #reference>
            <MiButton type="danger" plain :loading="loading.delete">
              {{ $t('Common.Delete') }}
            </MiButton>
          </template>
        </ElPopconfirm>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
import { ElDialog, ElPopconfirm } from 'element-plus';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { PropType, computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';

import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { misApi } from '~shared/api';
import { ProductDto } from '~shared/api/common';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

export default {
  name: 'ProductCrudModal',
  components: {
    MiButton,
    VeeForm,
    FormTextField,
    FieldGroupWrapper,
    FormActionsWrapper,
    ElDialog,
    ElPopconfirm,
  },
  emits: ['update:modelValue', 'action'],
  props: {
    formData: {
      type: Object as PropType<ProductDto>,
      default: () => ({ title: '', article: '', price: null, description: '' }),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initialValues = ref({ ...props.formData });
    const isNewProduct = computed(() => !initialValues.value.id);

    const loading = reactive({
      save: false,
      delete: false,
    });

    const validationSchema = object({
      title: string().required(I18nService.t('Validation.RequiredField')),
      article: string().required(I18nService.t('Validation.RequiredField')),
      price: string().nullable().required(I18nService.t('Validation.RequiredField')),
      description: string().nullable(),
    });

    const closeModal = () => {
      emit('update:modelValue');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmissionHandler<any> = async (values) => {
      loading.save = true;
      if (values.id) {
        const response = await misApi.common.product.updateItem(values.id, values);
        await store.dispatch('nomenclature/editItem', response?.data.data);
      } else {
        const response = await misApi.common.product.createItem(values);
        await store.dispatch('nomenclature/createItem', response?.data.data);
      }
      closeModal();
      loading.save = false;
    };

    const onDeleteButtonClick = async () => {
      loading.delete = true;
      if (typeof initialValues.value.id === 'number') {
        await misApi.common.product.deleteItem(initialValues.value.id);
        await store.dispatch('nomenclature/deleteItem', initialValues.value);
      }
      closeModal();
      loading.delete = false;
    };

    return {
      loading,
      validationSchema,
      initialValues,
      isNewProduct,
      onSubmit,
      onDeleteButtonClick,
    };
  },
};
</script>

<style lang="scss" src="./index.scss" />
