export const ICON_NAME = {
  LOGOUT: 'LOGOUT',
  PIE_CHART: 'PIE_CHART',
  LOCK: 'LOCK',
  MESSAGE: 'MESSAGE',
  BOARD: 'BOARD',
  SEARCH: 'SEARCH',
  BELL: 'BELL',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHEVRON_LEFT: 'CHEVRON_LEFT',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  CHEVRON_UP: 'CHEVRON_UP',
  PLUS: 'PLUS',
  CLOSE: 'CLOSE',
  DOUBLE_CHECKER: 'DOUBLE_CHECKER',
  PROVIDED: 'PROVIDED',
  CANCELED_ROUND: 'CANCELED_ROUND',
  TRASH: 'TRASH',
  RELOAD: 'RELOAD',
  BACK_ARROW: 'BACK_ARROW',
  RUSSIAN: 'RUSSIAN',
  ENGLISH: 'ENGLISH',
  NOTIFICATION: 'NOTIFICATION',
  UZBEKISTAN: 'UZBEKISTAN',
  SCAN: 'SCAN',
  SCAN_V2: 'SCAN_V2',
  PRINTER: 'PRINTER',
  PRINT: 'PRINT',
  EYE: 'EYE',
  VECTOR_TOP: 'VECTOR_TOP',
  VECTOR_BOT: 'VECTOR_BOT',
  CANCEL: 'CANCEL',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  DOWNLOAD: 'DOWNLOAD',
  LIST_BOX_OUTLINE: 'LIST_BOX_OUTLINE',
  HOSPITAL_BED: 'HOSPITAL_BED',
  DECREASE_PERCENTAGE: 'DECREASE_PERCENTAGE',
  INCREASE_PERCENTAGE: 'INCREASE_PERCENTAGE',
  TOOLTIP: 'TOOLTIP',
  DOLLAR: 'DOLLAR',
  D: 'D',
  ARROW: 'ARROW',
  V: 'V',
  WARN: 'WARN',
  DEVICE: 'DEVICE',
  CHAT: 'CHAT',
  TELEGRAM: 'TELEGRAM',
  TELEGRAM_V2: 'TELEGRAM_V2',
  GROUP: 'GROUP',
  UPLOAD: 'UPLOAD',
  TEMPLATE: 'TEMPLATE',
  PAPER: 'PAPER',
  MAIN_CONTINGENT: 'MAIN_CONTINGENT',
  PHARMACY: 'PHARMACY',
  HOSPITAL: 'HOSPITAL',
  LINE_CHART: 'LINE_CHART',
  CHECK: 'CHECK',
  CAMERA: 'CAMERA',
  HIDE_FILTERS: 'HIDE_FILTERS',
  PREGNANCY: 'PREGNANCY',
  AMBULANCE: 'AMBULANCE',
  FORM: 'FORM',
  FORM_V2: 'FORM_V2',
  STETHOSCOPE: 'STETHOSCOPE',
  SYRINGE: 'SYRINGE',
  CALENDAR: 'CALENDAR',
  CALENDAR_V2: 'CALENDAR_V2',
  DOCUMENT: 'DOCUMENT',
  STOCK: 'STOCK',
  STOCK_V2: 'STOCK_V2',
  GEAR: 'GEAR',
  BRIEFCASE: 'BRIEFCASE',
  STRONGBOX: 'STRONGBOX',
  STAR: 'STAR',
  BANKNOTE: 'BANKNOTE',
  DONUT_CHART: 'DONUT_CHART',
  GROUP_V2: 'GROUP_V2',
  SQUARE_MATRIX_4: 'SQUARE_MATRIX_4',
  PIN: 'PIN',
  BED_SINGLE: 'BED_SINGLE',
  ANALYTICS_APPOINTMENT_CLINIC: 'ANALYTICS_APPOINTMENT_CLINIC',
  ANALYTICS_APPOINTMENT_DOCTOR: 'ANALYTICS_APPOINTMENT_DOCTOR',
  ANALYTICS_APPOINTMENT_MAN: 'ANALYTICS_APPOINTMENT_MAN',
  ANALYTICS_APPOINTMENT_NURSE: 'ANALYTICS_APPOINTMENT_NURSE',
  ANALYTICS_APPOINTMENT_V2: 'ANALYTICS_APPOINTMENT_V2',
  ANALYTICS_APPOINTMENT_V3: 'ANALYTICS_APPOINTMENT_V3',
  ANALYTICS_APPOINTMENT_V4: 'ANALYTICS_APPOINTMENT_V4',
  ANALYTICS_APPOINTMENT_WOMAN: 'ANALYTICS_APPOINTMENT_WOMAN',
  ANALYTICS_ATTACHED_POPULATION: 'ANALYTICS_ATTACHED_POPULATION',
  ANALYTICS_NEWBORNS: 'ANALYTICS_NEWBORNS',
  ANALYTICS_PREGNANTS: 'ANALYTICS_PREGNANTS',
  ANALYTICS_DISABLED_PEOPLE: 'ANALYTICS_DISABLED_PEOPLE',
  ANALYTICS_D_REGISTRATION: 'ANALYTICS_D_REGISTRATION',
  ANALYTICS_IV_HEALTH_GROUP: 'ANALYTICS_IV_HEALTH_GROUP',
  CITY_WORKER: 'CITY_WORKER',
  DOCTOR_MALE: 'DOCTOR_MALE',
  GROUPS_CROWD: 'GROUPS_CROWD',
  HEALTH_WORKER: 'HEALTH_WORKER',
  NURSE: 'NURSE',
  NOTES: 'NOTES',
  HEARTBEAT_V1: 'HEARTBEAT_V1',
  HEARTBEAT_V2: 'HEARTBEAT_V2',
  HEARTBEAT_V3: 'HEARTBEAT_V3',
  HEARTBEAT_V4: 'HEARTBEAT_V4',
  HEARTBEAT_V5: 'HEARTBEAT_V5',
  FOREFINGER_V1: 'FOREFINGER_V1',
  FOREFINGER_V2: 'FOREFINGER_V2',
  FOREFINGER_V3: 'FOREFINGER_V3',
  FOREFINGER_V4: 'FOREFINGER_V4',
  INFO: 'INFO',
  DOT: 'DOT',
  DOT_V2: 'DOT_V2',
  KEBAB_MENU: 'KEBAB_MENU',
  PROFILE: 'PROFILE',
  DOWNLOAD_V2: 'DOWNLOAD_V2',
  CLOSE_CIRCLE: 'CLOSE_CIRCLE',
  PHARMACY_V2: 'PHARMACY_V2',
  PILL: 'PILL',
  CIRCLE_CHECK: 'CIRCLE_CHECK',
  PLAY: 'PLAY',
  PENCIL: 'PENCIL',
} as const;
