<template>
  <ElDialog
    width="100%"
    class="income-crud-modal"
    :title="isNewIncome ? $t('Warehouse.AddWarehouse') : $t('Warehouse.EditWarehouse')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ProductCrudModal
      v-if="nestedModalData.product.visible"
      v-model="nestedModalData.product.visible"
      :form-data="nestedModalData.product.data"
      append-to-body />
    <SupplierCrudModal
      v-if="nestedModalData.supplier.visible"
      v-model="nestedModalData.supplier.visible"
      :form-data="nestedModalData.supplier.data"
      append-to-body />
    <WarehouseCrudModal
      v-if="nestedModalData.warehouse.visible"
      v-model="nestedModalData.warehouse.visible"
      :form-data="nestedModalData.warehouse.data"
      append-to-body />
    <VeeForm
      class="income-crud-form"
      :validation-schema="validationSchema"
      :initial-values="initialData.values"
      v-slot="{ meta, values }"
      @submit="onSubmit">
      <div class="income-crud-form__field-groups">
        <FieldGroupWrapper
          :title="$t('Supplier.SupplierInformation')"
          :grid-columns="'three-columns'">
          <div class="income-crud-form__left-field-group">
            <FormSearchSelectField
              field-name="supplier_id"
              field-object-name="supplier"
              :fetch-data="misApi.common.supplier.getItems"
              :placeholder="$t('Supplier.FullNameVendorNumber')"
              show-create-option
              @create="onSupplierCreateButtonClick" />
            <FormTextField
              field-name="supplier.title"
              :label="$t('Supplier.NameSupplier')"
              disabled
              :in-column="false"
              only-value />
            <FormTextField
              field-name="supplier.phone"
              :label="$t('Supplier.PhoneNumber')"
              disabled
              :in-column="false"
              only-value />
            <FormTextField
              field-name="supplier.contact_person"
              :label="$t('Supplier.ContactPerson')"
              disabled
              :in-column="false"
              only-value />
          </div>
          <div />
          <div class="income-crud-form__right-field-group">
            <FormSearchSelectField
              field-name="warehouse_id"
              field-object-name="warehouse"
              :label="$t('Warehouse.Warehouse')"
              :fetch-data="misApi.common.warehouse.getItems"
              :in-column="false"
              space-between
              show-create-option
              @create="onWarehouseCreateButtonClick" />
            <FormDateField
              field-name="planed_income_date"
              :label="$t('Income.ArrivalDate')"
              :in-column="false"
              :value-format="DATE_FORMAT"
              space-between />
            <FormTextField
              field-name="status"
              :label="$t('Income.Status')"
              disabled
              :in-column="false"
              only-value>
              <StatusTag
                :title="values.status ? $t(`Income.Statuses.${values.status}`) : ''"
                :value="values.status" />
            </FormTextField>
          </div>
        </FieldGroupWrapper>
        <FieldGroupWrapper :title="$t('Income.Goods')">
          <IncomeProductsTableFillingForm
            @table:add="addProductInTable"
            @product:create="onProductCreateButtonClick" />
          <IncomeProductsTable
            :loading="loading.table"
            :items="tableData.products"
            @table:delete="deleteProductInTable"
            @table:update="updateProductInTable" />
          <FormTotalAmount :total-amount="tableDataTotalSum" />
        </FieldGroupWrapper>
      </div>
      <FormActionsWrapper align="right">
        <MiButton
          v-if="actionOptions.isAddBtnShow"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ `+ ${$t('Income.AddParish')}` }}
        </MiButton>
        <MiButton
          v-if="actionOptions.isSaveBtnShow"
          type="primary"
          native-type="submit"
          :disabled="actionOptions.isSaveBtnDisabled || (isTablesEqual && !meta.dirty)"
          :loading="loading.save"
          >{{ $t('Common.Save') }}
        </MiButton>
        <ElPopconfirm
          v-if="actionOptions.isAcceptBtnShow"
          :disabled="actionOptions.isAcceptBtnDisabled"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="onAcceptIncomeButtonClick(values)">
          <template #reference>
            <MiButton
              type="primary"
              plain
              :loading="loading.accept"
              :disabled="actionOptions.isAcceptBtnDisabled">
              {{ $t('Income.AcceptIncome') }}
            </MiButton>
          </template>
        </ElPopconfirm>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'IncomeCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElNotification, ElDialog, ElPopconfirm } from 'element-plus';
import isEqual from 'lodash.isequal';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { object, string, number } from 'yup';

// eslint-disable-next-line import/no-internal-modules
import IncomeProductsTable from './IncomeProductsTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import IncomeProductsTableFillingForm from './IncomeProductsTableFillingForm/index.vue';
import { IncomeFormData } from './model';

import { DATE_FORMAT } from '~shared/config';
import { I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { misApi } from '~shared/api';
import { IncomeProductItem, IncomeWithProductsDto } from '~shared/api/common';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormTotalAmount from '@/components/common/form/FormTotalAmount/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';
import ProductCrudModal from '@/components/warehouse/modals/ProductCrudModal/index.vue';
import SupplierCrudModal from '@/components/warehouse/modals/SupplierCrudModal/index.vue';
import WarehouseCrudModal from '@/components/warehouse/modals/WarehouseCrudModal/index.vue';

const props = withDefaults(defineProps<{ formData?: IncomeWithProductsDto | null }>(), {
  formData: null,
});

const emit = defineEmits(['select', 'create', 'update:modelValue', 'action']);

const store = useStore();

const nestedModalData = ref({
  product: { visible: false, data: { title: '', article: '', price: null, description: '' } },
  supplier: {
    visible: false,
    data: { title: '', phone: '', contact_person: '', description: '' },
  },
  warehouse: {
    visible: false,
    data: {
      title: '',
      address: '',
      description: '',
    },
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const initialData = reactive<{ values: Omit<IncomeFormData, 'products'> }>({
  values: props.formData ?? {
    count_products: null,
    amount_products: null,
    status: 'created',
    supplier_id: null,
    supplier: null,
    warehouse_id: null,
    warehouse: null,
    income_date: '',
    planed_income_date: '',
  },
});
// eslint-disable-next-line vue/no-setup-props-destructure
const tableData = reactive<{ products: IncomeProductItem[] }>({
  products: props.formData ? [...props.formData.products] : [],
});
const tableDataTotalSum = computed(() =>
  tableData.products.reduce((acc, curr) => curr.count * curr.price + acc, 0)
);
const isTablesEqual = computed(() => {
  return isEqual(tableData.products, props.formData?.products);
});

const isNewIncome = computed(() => !props.formData?.id);
const actionOptions = computed(() => ({
  isAddBtnShow: isNewIncome.value,
  isSaveBtnShow: !isNewIncome.value,
  isAcceptBtnShow: !isNewIncome.value,
  isAcceptBtnDisabled: initialData.values.status === 'accepted',
  isSaveBtnDisabled: initialData.values.status === 'accepted',
}));

const loading = reactive({
  data: false,
  save: false,
  accept: false,
  table: false,
});

const validationSchema = object({
  supplier_id: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
  warehouse_id: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
  planed_income_date: string().nullable().required(I18nService.t('Validation.RequiredField')),
});

const addProductInTable = (values: IncomeProductItem) => {
  tableData.products.push({ ...values, count: values.planed_count });
};

const updateProductInTable = (payload: IncomeProductItem) => {
  const index = tableData.products.findIndex((f) => f.product_id === payload.product_id);
  tableData.products[index] = payload;
};
const deleteProductInTable = (id: number) => {
  tableData.products = tableData.products.filter((f) => f.product_id !== id);
};

const closeModal = () => {
  emit('update:modelValue');
};

const onProductCreateButtonClick = async (value: string) => {
  nestedModalData.value.product.data.title = value;
  nestedModalData.value.product.visible = true;
};
const onSupplierCreateButtonClick = async (value: string) => {
  nestedModalData.value.supplier.data.title = value;
  nestedModalData.value.supplier.visible = true;
};
const onWarehouseCreateButtonClick = async (value: string) => {
  nestedModalData.value.warehouse.data.title = value;
  nestedModalData.value.warehouse.visible = true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit: SubmissionHandler<any> = async (values) => {
  loading.save = true;
  const valuesDef = {
    ...values,
    products: tableData.products,
  };
  if (tableData.products.length > 0) {
    if (values.id) {
      const response = await misApi.common.income.updateItem(values.id, valuesDef);
      await store.dispatch('incomes/editItem', response?.data.data);
    } else {
      const response = await misApi.common.income.createItem(valuesDef);
      store.dispatch('incomes/createItem', response?.data.data);
    }
    closeModal();
    loading.save = false;
  } else {
    ElNotification({
      type: 'error',
      title: 'Заполните таблицу товаров',
    });
    loading.save = false;
  }
};
// TODO: избавиться от any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onAcceptIncomeButtonClick = async (values: any) => {
  loading.accept = true;
  const valuesDef = {
    ...values,
    products: tableData.products,
  };
  if (values.id && tableData.products.length > 0) {
    const response = await misApi.common.income.acceptedItem(values.id, valuesDef);
    await store.dispatch('incomes/editItem', response?.data.data);
    closeModal();
    loading.accept = false;
  } else {
    ElNotification({
      type: 'error',
      title: 'Заполните таблицу товаров',
    });
    loading.accept = false;
  }
};
</script>

<style lang="scss" src="./index.scss" />
