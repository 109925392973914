/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DrugTradeNameResource {
  id: number;
  title: string;
  GTIN: string;
  can_used_as_drug: boolean;
  can_used_as_solvent: boolean;
  /** @format date */
  created_at: string;
}

export interface INNResource {
  id: number;
  title: string;
  number: string;
}

export interface DiseaseCodeResource {
  code?: string;
  title?: string;
}

export interface DrugIssueResource {
  id: number;
  count: number;
  sale_price: number;
  /** @format date */
  created_at: string;
}

export interface UserNameResource {
  id: number;
  surname: string | null;
  name: string;
  patronymic: string | null;
}

export interface DrugResource {
  id: number;
  serial: string;
  number: string;
  labeling_code: string;
  /** @format date */
  created_at: string;
  /** @format date */
  manufacture_date: string;
  /** @format date */
  expire_date: string;
}

export interface PrescriptionHistoryResource {
  id: number;
  data: {
    status?: {
      old_value: PrescriptionStatusEnum;
      new_value: PrescriptionStatusEnum;
    };
    cancellation_reason?: {
      old_value: PrescriptionCancellationReasonEnum;
      new_value: PrescriptionCancellationReasonEnum;
    };
    cancellation_comment?: {
      old_value: string | null;
      new_value: string | null;
    };
    substance_dosage?: {
      old_value: number | null;
      new_value: number | null;
    };
    measurement_unit?: {
      old_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
      new_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
    };
    dosage_form?: {
      old_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
      new_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
    };
    drug_administration_route?: {
      old_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
      new_value: {
        ru?: string;
        uz?: string;
        en?: string;
      };
    };
    start_date?: {
      /** @format date */
      old_value: string | null;
      /** @format date */
      new_value: string | null;
    };
    end_date?: {
      /** @format date */
      old_value: string | null;
      /** @format date */
      new_value: string | null;
    };
    administration_frequency?: {
      old_value: number | null;
      new_value: number | null;
    };
    single_dose?: {
      old_value: number | null;
      new_value: number | null;
    };
    administration_frequency_measure?: {
      old_value: AdministrationFrequencyMeasureEnum;
      new_value: AdministrationFrequencyMeasureEnum;
    };
    description?: {
      old_value: string | null;
      new_value: string | null;
    };
    count_days?: {
      old_value: string | null;
      new_value: string | null;
    };
    times?: {
      old_value: string[];
      new_value: string[];
    };
  };
  description: string | null;
  created_at: string;
}

export interface DrugAdministrationRouteResource {
  id: number;
  title: string;
}

export interface DrugAdministrationTimeResource {
  id: number;
  /** @format time */
  start_time: string;
  /** @format time */
  end_time: string;
}

export interface SubstanceDosageResource {
  id: number;
  dosage: number;
}

export interface PharmacyResource {
  id: number;
  title: string;
  legal_title: string;
  tin: string;
  address: string;
  /** @format date */
  created_at: string;
}

export interface MeasurementUnitResource {
  id: number;
  title: string;
}

export interface PrescriptionResource {
  id: number;
  number: string;
  safe_code: string;
  status: PrescriptionStatusEnum;
  type: PrescriptionTypeEnum;
  /** @format date */
  expiration_date: string;
  cancellation_reason: PrescriptionCancellationReasonEnum;
  cancellation_comment: string | null;
  signed_type: PrescriptionSignedTypeEnum;
  /** @format date */
  created_at: string;
}

export interface SolventResource {
  id: number;
  drug_trade_name: DrugTradeNameResource;
}

export interface DispensaryResource {
  id: number;
  /** @format date */
  registration_date: string;
  /** @format date */
  deregistration_date: string;
  /** @format date */
  created_at: string;
}

export type PrescriptionStatusEnum =
  | 'created'
  | 'issued'
  | 'awaiting_confirmation'
  | 'partially_issued'
  | 'approve'
  | 'revision'
  | 'expired'
  | 'canceled';

export type PrescriptionCancellationReasonEnum = 'medical_contraindication' | 'other';

export type PrescriptionSignedTypeEnum = 'certificate' | 'code';

export type PrescriptionConfidantTypeEnum = 'relative' | 'nurse';

export type RelativeEnum =
  | 'child'
  | 'parent'
  | 'spouse'
  | 'sibling'
  | 'grandparent'
  | 'grandchild'
  | 'other';

export type AppointmentTypeEnum =
  | 'unplanned'
  | 'primary_examination'
  | 'department_examination'
  | 'specialist_examination';

export type AppointmentStatusEnum =
  | 'created'
  | 'wait_for_payment'
  | 'wait_for_doctor_approve'
  | 'approved'
  | 'canceled'
  | 'provided'
  | 'in_progress'
  | 'waiting'
  | 'on_the_way';

export type RevisionResource = {
  id: string;
  creator_name: string;
  comment: string;
} | null;

export interface ReimbursementCategoryResource {
  id: number;
  name: string;
  available_for_issue: boolean;
}

export interface ReimbursementDrugResource {
  id: number;
  price: number;
  available_for_issue: boolean;
  category: ReimbursementCategoryResource;
  trade_name: DrugTradeNameResource & {
    inns: {
      inn: INNResource;
    }[];
    substance_dosages: (SubstanceDosageResource & {
      measurement_unit: MeasurementUnitResource;
    })[];
    dosage_form: DosageFormResource;
  };
  fabricator: string;
  country: string;
}

export interface DrugAppointmentResource {
  id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  single_dose: string | null;
  administration_frequency: number;
  administration_frequency_measure: AdministrationFrequencyMeasureEnum;
  description: string | null;
  patient_id: number;
  count_days: number;
  /** @format date */
  created_at: string;
  doctor_id: number;
}

export type MedicationResource =
  | INNResource
  | (DrugTradeNameResource & {
      inns: {
        inn: INNResource;
        substance_dosage: SubstanceDosageResource & {
          measurement_unit: MeasurementUnitResource;
        };
      }[];
    });

export interface PrescriptionConfidantResource {
  type: PrescriptionConfidantTypeEnum;
  user: UserNameResource | RelativeResource;
}

export interface RelativeResource {
  id: number;
  name: string;
  type: RelativeEnum;
}

export interface DosageFormResource {
  id: number;
  title: string;
}

export interface ErrorResource {
  message?: string | null;
}

export interface CreatePrescriptionRequestBody {
  patient_id: number;
  doctor_id: number;
  type: PrescriptionTypeEnum;
  medical_case_id: number;
  prescriptions: {
    inn_id?: number;
    drug_trade_name_id?: number;
    dosage_form_id: number;
    substance_dosage_id: number;
    single_dose?: string;
    drug_administration_route_id?: number;
    administration_frequency: number;
    administration_frequency_measure: AdministrationFrequencyMeasureEnum;
    /** @minItems 1 */
    times?: string[];
    count_days: number;
    /** @format date */
    start_date?: string;
    /** @format date */
    end_date?: string;
    description?: string;
    atc_id?: number;
    /** @minItems 1 */
    solvents?: {
      drug_trade_name_id: number;
    }[];
  }[];
  confidant_type?: PrescriptionConfidantTypeEnum;
  confidant_user_id?: number;
}

export interface UpdatePrescriptionRequestBody {
  dosage_form_id: number;
  substance_dosage_id: number;
  single_dose?: string;
  drug_administration_route_id?: number;
  administration_frequency: number;
  administration_frequency_measure: AdministrationFrequencyMeasureEnum;
  /** @minItems 1 */
  times?: string[];
  count_days: number;
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
  description?: string;
  atc_id?: number;
  /** @minItems 1 */
  solvents?: {
    id?: number;
    drug_trade_name_id: number;
  }[];
  confidant_type?: PrescriptionConfidantTypeEnum;
  confidant_user_id?: number;
}

export interface CancelPrescriptionRequestBody {
  cancellation_reason: PrescriptionCancellationReasonEnum;
  cancellation_comment?: string;
}

export interface RevisionPrescriptionRequestBody {
  comment: string;
}

export interface IssuePrescriptionRequestBody {
  personal_identification?: PersonalIdentificationEnum;
  issued_by_id: number;
  /** @minItems 1 */
  issues: {
    prescription_id: number;
    /** @minItems 1 */
    drugs: {
      drug_trade_name_id: number;
      labeling_code?: string;
      serial?: string;
      count: number;
      sale_price: number;
      /** @format date */
      manufacture_date?: string;
      /** @format date */
      expire_date?: string;
    }[];
  }[];
}

export interface SigningPrescriptionsRequestBody {
  /** @minItems 1 */
  prescriptions: number[];
  token?: string;
  cert?: string;
}

export interface CreateReimbursementCategoryRequestBody {
  name: string;
  diagnoses: string[];
  inns: number[];
}

export interface EditReimbursementCategoryRequestBody {
  name: string;
  diagnoses: string[];
  inns: number[];
}

export interface AddDrugInReimbursementCategoryRequestBody {
  drug_trade_name_id: number;
  price: number;
  available_for_issue: boolean;
}

export interface EditReimbursementDrugRequestBody {
  category_id: number;
  drug_trade_name_id: number;
  price: number;
  available_for_issue: boolean;
}

export interface DoctorResource {
  id: number;
  name: string;
  phone: string | null;
}

export interface PatientNameResource {
  id: number;
  surname: string | null;
  name: string;
  patronymic: string | null;
  phone: string | null;
}

/** Метаданные для ответов с пагинацией */
export interface PaginationMeta {
  per_page: number;
  total: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

export type AdministrationFrequencyMeasureEnum = 'day' | 'full_day' | 'week' | 'month';

export type PersonalIdentificationEnum = 'face' | 'token';

export type PrescriptionTypeEnum = 'reimbursement' | 'simple';

export interface ATCResource {
  id: number;
  title: string;
  code: string;
  is_leaf: boolean;
}

export interface NurseResource {
  id: number;
  name: string;
}

export interface ListPrescriptionsParams {
  patient_id?: number;
  doctor_id?: number;
  medical_case_id?: number;
  /**
   * Статусы рецептов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: PrescriptionStatusEnum[];
  /**
   * Статусы рецептов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: PrescriptionStatusEnum[];
  type?: PrescriptionTypeEnum;
  appointment_id?: number;
  inn_id?: number;
  disease_code?: string;
  /**
   * @minLength 10
   * @maxLength 10
   */
  number?: string;
  /**
   * @minLength 6
   * @maxLength 6
   */
  safe_code?: string;
  /**
   * @minLength 14
   * @maxLength 14
   */
  pinfl?: string;
  is_confidant?: boolean;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
}

export interface ListPrescriptionsHistoryParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  patient_id: number;
}

export interface ExportPrescriptionsForPharmacistParams {
  patient_id?: number;
  /**
   * Статусы рецептов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: PrescriptionStatusEnum[];
  /**
   * Статусы рецептов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: PrescriptionStatusEnum[];
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export type ExportPrescriptionsForPharmacistData = any;

export interface ExportPrescriptionsForDoctorParams {
  patient_id?: number;
  doctor_id?: number;
  /**
   * Статусы рецептов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: PrescriptionStatusEnum[];
  /**
   * Статусы рецептов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: PrescriptionStatusEnum[];
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export type ExportPrescriptionsForDoctorData = any;

export interface ExportPrescriptionsByClinicParams {
  patient_id?: number;
  doctor_id?: number;
  /**
   * Статусы рецептов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: PrescriptionStatusEnum[];
  /**
   * Статусы рецептов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: PrescriptionStatusEnum[];
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export type ExportPrescriptionsByClinicData = any;

export type IssuePrescriptionData = any;

export interface ExportReimbursementPrescriptionsByClinicParams {
  patient_id?: number;
  doctor_id?: number;
  /**
   * Статусы рецептов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: PrescriptionStatusEnum[];
  /**
   * Статусы рецептов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: PrescriptionStatusEnum[];
  inn_id?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export type ExportReimbursementPrescriptionsByClinicData = any;

export type SigningPrescriptionsData = any;

export type VerificationNotifyingData = any;

export interface ListReimbursementCategoryParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  diagnoses?: string[];
  inns?: number[];
}

export interface ListReimbursementDrugParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  drug_trade_names?: number[];
  inns?: number[];
}

export interface ListDrugTradeNamesParams {
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  inn_id?: number;
  substance_dosage_id?: number;
  dosage_form_id?: number;
  drug_administration_route_id?: number;
  atc_id?: number;
  can_used_as_drug?: boolean;
  can_used_as_solvent?: boolean;
}

export interface FindDrugTradeNameByGtinParams {
  GTIN: string;
}

export interface ListInnParams {
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  drug_trade_name_id?: number;
  atc_id?: number;
  prescription_type?: PrescriptionTypeEnum;
  disease_codes?: any[];
}

export interface ListSubstanceDosagesParams {
  /** Поиск по названию или другим полям */
  search?: string;
  inn_id?: number;
  dosage_form_id?: number;
  drug_administration_route_id?: number;
  drug_trade_name_id?: number;
}

export interface ListDosageFormsParams {
  /** Поиск по названию или другим полям */
  search?: string;
  inn_id?: number;
  substance_dosage_id?: number;
  drug_administration_route_id?: number;
  drug_trade_name_id?: number;
  atc_id?: number;
}

export interface ListDrugAdministrationRoutesParams {
  /** Поиск по названию или другим полям */
  search?: string;
  inn_id?: number;
  dosage_form_id?: number;
  substance_dosage_id?: number;
  drug_trade_name_id?: number;
}

export interface ListAtcParams {
  /** Поиск по названию или другим полям */
  search?: string;
  atc_id?: number;
  inn_id?: number;
  drug_trade_name_id?: number;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://stage.test.dmed.uz/api/prescriptions/v1',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Prescriptions Api v1
 * @version 1.0.0
 * @baseUrl https://stage.test.dmed.uz/api/prescriptions/v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  prescriptions = {
    /**
     * No description
     *
     * @tags Рецепты
     * @name ListPrescriptions
     * @summary Получить список рецептов
     * @request GET:/prescriptions
     * @secure
     */
    listPrescriptions: (query: ListPrescriptionsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (PrescriptionResource & {
            revision?: RevisionResource;
            drug_appointment: DrugAppointmentResource & {
              patient: PatientNameResource;
              medication: MedicationResource;
              substance_dosage: SubstanceDosageResource & {
                measurement_unit: MeasurementUnitResource;
              };
              dosage_form: DosageFormResource;
              drug_administration_route: DrugAdministrationRouteResource;
              drug_administration_times: DrugAdministrationTimeResource[];
              doctor: DoctorResource;
              issues: (DrugIssueResource & {
                issued_by: UserNameResource & {
                  pharmacies: PharmacyResource[];
                };
                drug: DrugResource & {
                  drug_trade_name: DrugTradeNameResource;
                };
              })[];
            };
            dispensaries?: (DispensaryResource & {
              disease_code?: DiseaseCodeResource;
            })[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/prescriptions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name CreatePrescription
     * @summary Создать рецепт
     * @request POST:/prescriptions
     * @secure
     */
    createPrescription: (data: CreatePrescriptionRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: PrescriptionResource[];
        },
        ErrorResource
      >({
        path: `/prescriptions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ListPrescriptionsHistory
     * @summary Получить список изменений рецептов
     * @request GET:/prescriptions/histories
     * @secure
     */
    listPrescriptionsHistory: (query: ListPrescriptionsHistoryParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (PrescriptionHistoryResource & {
            editor: UserNameResource;
            prescription: PrescriptionResource & {
              drug_appointment: DrugAppointmentResource & {
                medication: MedicationResource;
              };
            };
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/prescriptions/histories`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ExportPrescriptionsForPharmacist
     * @summary Получить список рецептов в формате excel для фармацевта
     * @request GET:/prescriptions/export/pharmacist
     * @secure
     */
    exportPrescriptionsForPharmacist: (
      query: ExportPrescriptionsForPharmacistParams,
      params: RequestParams = {}
    ) =>
      this.request<ExportPrescriptionsForPharmacistData, ErrorResource>({
        path: `/prescriptions/export/pharmacist`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ExportPrescriptionsForDoctor
     * @summary Получить список рецептов в формате excel для доктора
     * @request GET:/prescriptions/export/doctor-prescriptions
     * @secure
     */
    exportPrescriptionsForDoctor: (
      query: ExportPrescriptionsForDoctorParams,
      params: RequestParams = {}
    ) =>
      this.request<ExportPrescriptionsForDoctorData, ErrorResource>({
        path: `/prescriptions/export/doctor-prescriptions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ExportPrescriptionsByClinic
     * @summary Получить список рецептов в формате excel для директора
     * @request GET:/prescriptions/export/by-clinic
     * @secure
     */
    exportPrescriptionsByClinic: (
      query: ExportPrescriptionsByClinicParams,
      params: RequestParams = {}
    ) =>
      this.request<ExportPrescriptionsByClinicData, ErrorResource>({
        path: `/prescriptions/export/by-clinic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name IssuePrescription
     * @summary Выдать препараты по рецепту
     * @request POST:/prescriptions/issue
     * @secure
     */
    issuePrescription: (data: IssuePrescriptionRequestBody, params: RequestParams = {}) =>
      this.request<IssuePrescriptionData, any>({
        path: `/prescriptions/issue`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ExportReimbursementPrescriptionsByClinic
     * @summary Получить список рецептов в формате excel для директора
     * @request GET:/prescriptions/export/by-clinic-reimbursement
     * @secure
     */
    exportReimbursementPrescriptionsByClinic: (
      query: ExportReimbursementPrescriptionsByClinicParams,
      params: RequestParams = {}
    ) =>
      this.request<ExportReimbursementPrescriptionsByClinicData, ErrorResource>({
        path: `/prescriptions/export/by-clinic-reimbursement`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name GetPrescription
     * @summary Получить рецепт
     * @request GET:/prescriptions/{prescriptionId}
     * @secure
     */
    getPrescription: (prescriptionId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: PrescriptionResource & {
            drug_appointment: DrugAppointmentResource & {
              medication: MedicationResource;
              substance_dosage: SubstanceDosageResource & {
                measurement_unit: MeasurementUnitResource;
              };
              dosage_form: DosageFormResource;
              drug_administration_route: DrugAdministrationRouteResource;
              drug_administration_times: DrugAdministrationTimeResource[];
              atc: ATCResource;
            };
            solvents: SolventResource[];
            confidant: PrescriptionConfidantResource | null;
            revision: RevisionResource;
            dispensaries?: (DispensaryResource & {
              disease_code?: DiseaseCodeResource;
            })[];
          };
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name UpdatePrescription
     * @summary Обновить рецепт
     * @request PUT:/prescriptions/{prescriptionId}
     * @secure
     */
    updatePrescription: (
      prescriptionId: number,
      data: UpdatePrescriptionRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: PrescriptionResource & {
            drug_appointment: DrugAppointmentResource & {
              medication: MedicationResource;
              substance_dosage: SubstanceDosageResource & {
                measurement_unit: MeasurementUnitResource;
              };
              dosage_form: DosageFormResource;
              drug_administration_route: DrugAdministrationRouteResource;
              atc?: ATCResource;
            };
          };
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name CancelPrescription
     * @summary Отменить рецепт
     * @request PUT:/prescriptions/{prescriptionId}/cancel
     * @secure
     */
    cancelPrescription: (
      prescriptionId: number,
      data: CancelPrescriptionRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: PrescriptionResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}/cancel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name SendForConfirmationPrescription
     * @summary Отправить рецепт на подтверждение
     * @request PUT:/prescriptions/{prescriptionId}/send-for-confirmation
     * @secure
     */
    sendForConfirmationPrescription: (prescriptionId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: PrescriptionResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}/send-for-confirmation`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name ApprovePrescription
     * @summary Подтвердить рецепт
     * @request PUT:/prescriptions/{prescriptionId}/approve
     * @secure
     */
    approvePrescription: (prescriptionId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: PrescriptionResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}/approve`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name RevisionPrescription
     * @summary Отправить рецепт на доработку
     * @request PUT:/prescriptions/{prescriptionId}/revision
     * @secure
     */
    revisionPrescription: (
      prescriptionId: number,
      data: RevisionPrescriptionRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: PrescriptionResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}/revision`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name GetPatientPrescription
     * @summary Получить идентификатор пациента
     * @request GET:/prescriptions/{prescriptionId}/get-patient
     * @secure
     */
    getPatientPrescription: (prescriptionId: number, params: RequestParams = {}) =>
      this.request<
        {
          patient_id: number;
        },
        ErrorResource
      >({
        path: `/prescriptions/${prescriptionId}/get-patient`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name SigningPrescriptions
     * @summary Подписание рецептов
     * @request POST:/prescriptions/signing
     * @secure
     */
    signingPrescriptions: (data: SigningPrescriptionsRequestBody, params: RequestParams = {}) =>
      this.request<SigningPrescriptionsData, any>({
        path: `/prescriptions/signing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Рецепты
     * @name VerificationNotifying
     * @summary Уведомить пользователя для получения препарата
     * @request GET:/prescriptions/{userId}/verification-notifying
     * @secure
     */
    verificationNotifying: (userId: number, params: RequestParams = {}) =>
      this.request<VerificationNotifyingData, ErrorResource>({
        path: `/prescriptions/${userId}/verification-notifying`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name ListReimbursementCategory
     * @summary Получить список категорий
     * @request GET:/prescriptions/categories
     * @secure
     */
    listReimbursementCategory: (
      query: ListReimbursementCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (ReimbursementCategoryResource & {
            diagnosis: DiseaseCodeResource[];
            inns: INNResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/prescriptions/categories`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name CreateReimbursementCategory
     * @summary Создать категорию
     * @request POST:/prescriptions/categories
     * @secure
     */
    createReimbursementCategory: (
      data: CreateReimbursementCategoryRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ReimbursementCategoryResource & {
            diagnosis: DiseaseCodeResource[];
            inns: INNResource[];
          };
        },
        ErrorResource
      >({
        path: `/prescriptions/categories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name EditReimbursementCategory
     * @summary Отредактировать категорию
     * @request PUT:/prescriptions/categories/{category_id}
     * @secure
     */
    editReimbursementCategory: (
      categoryId: number,
      data: EditReimbursementCategoryRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ReimbursementCategoryResource & {
            diagnosis: DiseaseCodeResource[];
            inns: INNResource[];
          };
        },
        ErrorResource
      >({
        path: `/prescriptions/categories/${categoryId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name ChangeAvailableReimbursementCategory
     * @summary Сменить доступ к выдаче категории
     * @request PUT:/prescriptions/categories/{category_id}/change-available
     * @secure
     */
    changeAvailableReimbursementCategory: (categoryId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ReimbursementCategoryResource & {
            diagnosis: DiseaseCodeResource[];
            inns: INNResource[];
          };
        },
        ErrorResource
      >({
        path: `/prescriptions/categories/${categoryId}/change-available`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name ListReimbursementDrug
     * @summary Получить список рецептов
     * @request GET:/prescriptions/drugs
     * @secure
     */
    listReimbursementDrug: (query: ListReimbursementDrugParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ReimbursementDrugResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/prescriptions/drugs`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name AddDrugInReimbursementCategory
     * @summary Добавить препарат в категорию
     * @request POST:/prescriptions/categories/{category_id}/add-drug
     * @secure
     */
    addDrugInReimbursementCategory: (
      categoryId: number,
      data: AddDrugInReimbursementCategoryRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ReimbursementDrugResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/categories/${categoryId}/add-drug`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name EditReimbursementDrug
     * @summary Изменить препарат реимбурсации
     * @request PUT:/prescriptions/drugs/{drug_id}
     * @secure
     */
    editReimbursementDrug: (
      drugId: number,
      data: EditReimbursementDrugRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ReimbursementDrugResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/drugs/${drugId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реимбурсация
     * @name ChangeAvailableReimbursementDrug
     * @summary Сменить доступ к выдаче препарата
     * @request PUT:/prescriptions/drug/{drug_id}/change-available
     * @secure
     */
    changeAvailableReimbursementDrug: (drugId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ReimbursementDrugResource;
        },
        ErrorResource
      >({
        path: `/prescriptions/drug/${drugId}/change-available`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  drugTradeNames = {
    /**
     * No description
     *
     * @tags Торговые наименования
     * @name ListDrugTradeNames
     * @summary Получить список торговых наименований
     * @request GET:/drug-trade-names
     * @secure
     */
    listDrugTradeNames: (query: ListDrugTradeNamesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (DrugTradeNameResource & {
            inns: {
              inn: INNResource;
              substance_dosage: SubstanceDosageResource & {
                measurement_unit: MeasurementUnitResource;
              };
            }[];
            substance_dosages: (SubstanceDosageResource & {
              measurement_unit: MeasurementUnitResource;
            })[];
            dosage_form: DosageFormResource;
            drug_administration_route: DrugAdministrationRouteResource;
            atc: ATCResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/drug-trade-names`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Торговые наименования
     * @name FindDrugTradeNameByGtin
     * @summary Получить торговое название по gtin
     * @request GET:/drug-trade-names/find-by-gtin
     * @secure
     */
    findDrugTradeNameByGtin: (query: FindDrugTradeNameByGtinParams, params: RequestParams = {}) =>
      this.request<
        {
          data: DrugTradeNameResource & {
            inns: {
              inn: INNResource;
              substance_dosage: SubstanceDosageResource & {
                measurement_unit: MeasurementUnitResource;
              };
            }[];
            substance_dosages: (SubstanceDosageResource & {
              measurement_unit: MeasurementUnitResource;
            })[];
            dosage_form: DosageFormResource;
            drug_administration_route: DrugAdministrationRouteResource;
            atc: ATCResource;
          };
        },
        ErrorResource
      >({
        path: `/drug-trade-names/find-by-gtin`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Торговые наименования
     * @name GetReleasePriceDrugTradeName
     * @summary Получить отпускной цены торговое название
     * @request GET:/drug-trade-names/{drugTradeNameId}/release-price
     * @secure
     */
    getReleasePriceDrugTradeName: (drugTradeNameId: number, params: RequestParams = {}) =>
      this.request<
        {
          price: number;
        },
        ErrorResource
      >({
        path: `/drug-trade-names/${drugTradeNameId}/release-price`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  inn = {
    /**
     * No description
     *
     * @tags МНН
     * @name ListInn
     * @summary Получить список МНН
     * @request GET:/inn
     * @secure
     */
    listInn: (query: ListInnParams, params: RequestParams = {}) =>
      this.request<
        {
          data: INNResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/inn`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  substanceDosages = {
    /**
     * No description
     *
     * @tags Дозировки
     * @name ListSubstanceDosages
     * @summary Получить список дозировок
     * @request GET:/substance-dosages
     * @secure
     */
    listSubstanceDosages: (query: ListSubstanceDosagesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (SubstanceDosageResource & {
            measurement_unit: MeasurementUnitResource;
          })[];
        },
        ErrorResource
      >({
        path: `/substance-dosages`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  dosageForms = {
    /**
     * No description
     *
     * @tags Формы препарата
     * @name ListDosageForms
     * @summary Получить список форм препарата
     * @request GET:/dosage-forms
     * @secure
     */
    listDosageForms: (query: ListDosageFormsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: DosageFormResource[];
        },
        ErrorResource
      >({
        path: `/dosage-forms`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  drugAdministrationRoutes = {
    /**
     * No description
     *
     * @tags Способы приема
     * @name ListDrugAdministrationRoutes
     * @summary Получить список способов приема
     * @request GET:/drug-administration-routes
     * @secure
     */
    listDrugAdministrationRoutes: (
      query: ListDrugAdministrationRoutesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: DrugAdministrationRouteResource[];
        },
        ErrorResource
      >({
        path: `/drug-administration-routes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  atc = {
    /**
     * No description
     *
     * @tags АТХ
     * @name ListAtc
     * @summary Получить список АТХ
     * @request GET:/atc
     * @secure
     */
    listAtc: (query: ListAtcParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ATCResource[];
        },
        ErrorResource
      >({
        path: `/atc`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  clinics = {
    /**
     * No description
     *
     * @tags Клиника
     * @name ListClinicNurses
     * @summary Получить список патронажной медсестры
     * @request GET:/clinics/{clinicId}/nurses
     * @secure
     */
    listClinicNurses: (clinicId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: NurseResource[];
        },
        ErrorResource
      >({
        path: `/clinics/${clinicId}/nurses`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  patients = {
    /**
     * No description
     *
     * @tags Пациент
     * @name ListPatientRelatives
     * @summary Получить список родственников
     * @request GET:/patients/{patientId}/relatives
     * @secure
     */
    listPatientRelatives: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: RelativeResource[];
        },
        ErrorResource
      >({
        path: `/patients/${patientId}/relatives`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациент
     * @name CheckPatientReimbursement
     * @summary Проверка возможности назначения рецепт по реимбурсации пациенту
     * @request GET:/patients/{patientId}/check-reimbursement
     * @secure
     */
    checkPatientReimbursement: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          status: boolean;
        },
        ErrorResource
      >({
        path: `/patients/${patientId}/check-reimbursement`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
