<template>
  <MiSimpleTable :max-height="400" table-class="income-products-table" :data="props.items">
    <MiTableColumn prop="product.title" :label="$t('Nomenclature.NameOfProduct')" />
    <MiTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" width="150px" />
    <MiTableColumn prop="price" :label="$t('Income.Price')" width="150px" />
    <MiTableColumn prop="planed_count" :label="$t('Nomenclature.ExpectedQty')" width="200px" />
    <MiTableColumn prop="count" :label="$t('Nomenclature.QuantityReceived')" width="220px">
      <template #default="{ row }">
        <ElInputNumber
          v-model="row.count"
          :min="1"
          @change="$emit('table:update', row)"></ElInputNumber>
      </template>
    </MiTableColumn>

    <MiTableColumn prop="sum" :label="$t('Nomenclature.AmountSum')" width="150px">
      <template #default="{ row }">
        {{ row.price * row.count }}
      </template>
    </MiTableColumn>

    <MiTableColumn prop="actions">
      <template #default="{ row }">
        <div class="income-products-table-actions">
          <MiButton
            class="income-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.product_id)">
            <template #icon>
              <MiIcon icon="CLOSE" />
            </template>
          </MiButton>
        </div>
      </template>
    </MiTableColumn>
  </MiSimpleTable>
</template>

<script lang="ts">
export default {
  name: 'IncomeProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElInputNumber } from 'element-plus';

import { MiTableColumn, MiButton, MiSimpleTable, MiIcon } from '~shared/ui';
import { IncomeProductItem } from '~shared/api/common';

defineEmits(['table:delete', 'table:update']);

const props = defineProps<{ items: IncomeProductItem[] }>();
</script>

<style lang="scss" src="./index.scss" />
